import React from 'react';
import './Footer.scss';

import Logo300Image from 'assets/img/prod/globals/logo-300.png';
import LocationIconImage from 'assets/img/prod/icons/footer-contact-location.png';
import PhoneIconImage from 'assets/img/prod/icons/footer-contact-phone.png';
import Certificate1Image from 'assets/img/prod/globals/footer-certificate-1.png';
import Certificate2Image from 'assets/img/prod/globals/footer-certificate-2.png';

import { ReactComponent as LinkedinIcon } from 'assets/icons/globals/social-linkedin.svg';

const Footer = () => {
  /** @var object */
  const usefulLinks = [
    'CommunityTrust.com',
    'Terms & Conditions',
    'Accessibility',
    'Privacy',
  ];

  // render
  return (
    <footer id="mainFooter">
      <div className="container">
        <div className="row row-1">
          <div className="col col__location">
            <h3 className="col__title">
              <i className="icon">
                <img
                  src={LocationIconImage}
                  alt="Location"
                  className="el-img"
                />
              </i>
              Where we are
            </h3>

            <div className="col__content">
              <p>
                2350 Matheson Boulevard East<br />
                Mississauga, Ontario, L4W 5G9<br />
                ––<br />
                Mon – Fri: 8:30 a.m. – 5:00 p.m.
              </p>
            </div>
          </div>

          <div className="col col__contact">
            <h3 className="col__title">
              <i className="icon">
                <img
                  src={PhoneIconImage}
                  alt="Phone"
                  className="el-img"
                />
              </i>
              Talk to us
            </h3>

            <div className="col__content">
              <p>
                <strong>Toll Free:</strong> 1 (800) 268-1576<br />
                <strong>Local:</strong> (416) 763-2291  |  (416) 763-2444
              </p>

              <div className="social-links">
                <a
                  href="https://linkedin.com"
                  title="Connect on Linkedin"
                  rel="noopener noreferrer"
                  target="_blank"
                  className="item">
                  <LinkedinIcon />
                </a>
              </div>
            </div>
          </div>

          <div className="col col__links">
            <ul>
              {usefulLinks.map((link, key) => (
                <li
                  key={key}
                  className="item">
                  <a
                    href="https://communitytrust.com"
                    rel="noopener noreferrer"
                    target="_blank"
                    className="item__link">
                    {link}
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </div>

        <div className="row row-2">
          <div className="col col__info">
            <p>Community Trust Company is a member of the Questrade Group of Companies. Questrade Group of Companies means Questrade Financial Group and its affiliates that provide deposit, investment, loan, securities, mortgages and other products or services.</p>
          </div>

          <div className="col col__certificates">
            <img
              src={Certificate1Image}
              alt="Certificate 1"
              className="el-img"
            />
            <img
              src={Certificate2Image}
              alt="Certificate 2"
              className="el-img"
            />
          </div>
        </div>

        <div className="row row-3">
          <div className="col col__copyright">
            © {(new Date()).getFullYear()} Community Trust Company. All rights reserved.
          </div>

          <div className="col col__developer">
            <small>Developed by</small>
            <a
              href="https://trezentos.com.br"
              title="300 Comunicação"
              rel="noopener noreferrer"
              target="_blank"
              className="logo-300">
              <img
                src={Logo300Image}
                alt="300 Comunicação"
                className="el-img"
              />
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
