import React, { Component } from 'react';
import { isMobile } from 'react-device-detect';
import MaskedInput from 'react-text-mask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';

class Field extends Component {
  /** @var boolean */
  static formField = true;

  /**
   * constructor
   *
   * This is the main constructor
   * for the class.
   *
   * @type function
   * @since 0.0.1
   */
  constructor(props) {
    super(props);

    // set the field name
    this.fieldName = this.props.minmax
      ? `${this.props.name}-${this.props.minmax}`
      : this.props.name;

    // set the unique id
    const { uniqueId } = this.props;
    this.uniqueId = `${uniqueId}__field-${this.fieldName}`;
  }

  /**
   * componentDidMount
   *
   * Fires when the component
   * finishes mounting.
   *
   * @type hook
   * @since 0.0.1
   */
  componentDidMount() {
    // set the initial value
    // to the form state
    this.props.updateValue(prevFormData => {
      prevFormData[this.fieldName] = "";
      return { ...prevFormData };
    });
  }

  /**
   * getMask
   *
   * Retrieves the input mask based
   * on it's type and current value.
   *
   * @type function
   * @since 0.0.1
   *
   * @param any rawValue
   * @return object
   */
  getMask = rawValue => {
    /** @var number Mask placeholder index */
    const phIndex = rawValue.indexOf('_');
    if (phIndex > 0) rawValue = rawValue.slice(0, phIndex);

    // prepare the mask
    let mask;
    switch (this.props.type) {
      case 'currency':
        mask = createNumberMask({
          prefix: '',
          includeThousandsSeparator: true,
          thousandsSeparatorSymbol: '.',
          allowDecimal: false,
          integerLimit: 9,
          allowNegative: false,
          allowLeadingZeroes: false,
        });
        break;

      case 'date':
        mask = [
          /\d/, /\d/,
          '-',
          /\d/, /\d/,
          '-',
          /\d/, /\d/, /\d/, /\d/,
        ];
        break;

      case 'loanNumber':
        mask = [
          /\d/, /\d/, /\d/,
          /\d/, /\d/,
        ];
        break;

      case 'phone':
        mask = [
          '(', /\d/, /\d/, ')', ' ',
          /\d/, /\d/, /\d/, /\d/,
          '-',
          /\d/, /\d/, /\d/, /\d/,
        ];

        if (rawValue.length === 15) {
          mask.splice(6, 0, /\d/);
        }
        break;

      case 'year':
        mask = [/\d/, /\d/, /\d/, /\d/];
        break;

      default:
        mask = [];
        break;
    }

    return mask;
  }

  /**
   * getField
   *
   * Renders the field element based
   * on it's type.
   *
   * @type function
   * @since 0.0.1
   *
   * @param NA
   * @return JSX
   */
  getField = () => {
    const { type } = this.props;
    let { placeholder } = this.props;

    /** @var object */
    let mainAttributes = {
      id: this.uniqueId,
      name: this.fieldName,
      placeholder,
      onChange: this.handleOnChange,
      className: 'field',
      value: this.props.formData[this.fieldName] || "",
      ...([
        'email',
        'password',
      ].indexOf(this.props.type) !== -1 && {
        type: this.props.type,
      }),
      ...(this.props.onInput && {
        onInput: this.props.onInput,
      }),
      ...(this.props.autoComplete && {
        autoComplete: this.props.autoComplete,
      }),
      ...(this.props.elementRef && {
        ref: this.props.elementRef,
      }),
    }

    if (
      isMobile && [
        'date',
        'currency',
        'phone',
        'loanNumber',
        'year',
      ].indexOf(type) !== -1
    ) {
      mainAttributes = {
        ...mainAttributes,
        inputMode: 'numeric',
      }
    }

    /** @var object */
    const fieldTypes = {
      simpleMask: () => {
        // general masked input
        if ([
          'date',
          'phone',
          'year',
          'loanNumber',
        ].indexOf(type) !== -1) {
          return (
            <MaskedInput {...mainAttributes} mask={this.getMask} />
          );
        }

        // default
        else {
          return (
            <input {...mainAttributes} />
          );
        }
      },
      customMask: {
        currency: () => {
          const mask = this.getMask('');
          return (
            <MaskedInput {...mainAttributes} mask={mask} />
          );
        },
        paragraph: () => {
          if (this.props.rows)
            mainAttributes['rows'] = this.props.rows;
          return (
            <textarea {...mainAttributes} rows="4"></textarea>
          );
        },
      },
    }

    /** @var function */
    const typeFunc =
      typeof fieldTypes.customMask[type] !== 'undefined'
        ? fieldTypes.customMask[type]
        : fieldTypes.simpleMask;

    /** @var object */
    const field = typeFunc();

    // add prefix and suffix
    if (this.props.prefix || this.props.suffix) {
      const fieldExtras = {
        prefix: null,
        suffix: null,
      }

      for (let part in fieldExtras) {
        if (this.props[part]) fieldExtras[part] = (
          <span className={part}>{this.props[part]}</span>
        );
      }

      return (
        <div className="field-wrapper">
          {fieldExtras.prefix}
          {field}
          {fieldExtras.suffix}
        </div>
      );
    } else {
      return field;
    }
  }

  /**
   * handleOnChange
   *
   * Handles the 'onChange' event,
   * updating the specified value in
   * the form's state.
   *
   * @type function
   * @since 0.0.1
   *
   * @param object event
   * @return NA
   */
  handleOnChange = event => {
    const { value } = event.target;
    this.props.updateValue(prevFormData => {
      prevFormData[this.fieldName] = value;
      return { ...prevFormData };
    });
  }

  // render
  render() {
    /** @var object */
    const classNames = ['form__field'];
    if (this.props.type)
      classNames.push(`type-${this.props.type}`);
    if (this.props.invalid)
      classNames.push('form__field--invalid');
    if (this.props.required)
      classNames.push('form__field--required');

    return (
      <div className={classNames.join(' ')}>
        {this.props.label &&
          <label
            className="form__field-label"
            htmlFor={this.uniqueId}>
            <span className="text">{this.props.label}</span>
            {this.props.tooltip &&
              <span className="tooltip">
                <span className="tooltip__toggler">?</span>
                <span className="tooltip__content">
                  {this.props.tooltip}
                </span>
              </span>
            }
          </label>
        }
        {this.getField()}
        <small className="form__field-feedback">
          {this.props.invalid.message}
        </small>
      </div>
    );
  }
}

export default Field;
