import React from 'react';
import './Header.scss';

import { isMobile } from 'react-device-detect';

import LogoImage from 'assets/img/prod/globals/logo-desktop.png';

const Header = ({ currentStep }) => {
  return (
    <header id="mainHeader">
      <div className="container">
        <div className="left">
          <a
            href="https://www.communitytrust.com"
            title="Go back to the homepage"
            className="logo">
            <img
              src={LogoImage}
              alt="Community Trust - A Flexible Alternative"
              className="el-img"
            />
          </a>

          <div className="step-info">
            <div className="info">
              <small className="top">
                Mortgage Financing
              </small>
              <h1
                className={`step-name ${!currentStep.name ? 'loading' : ''}`.trim()}>
                {currentStep.name || 'Loading...'}
              </h1>
            </div>

            {isMobile &&
              <div className="right">
                <div className="step-indicator">
                  <span>Step:</span>
                  <span>
                    {currentStep.index ? `${currentStep.index}/4` : '-'}
                  </span>
                </div>
              </div>
            }
          </div>
        </div>

        {!isMobile &&
          <div className="right">
            <div className="step-indicator">
              <span>Step:</span>
              <span>
                {currentStep.index ? `${currentStep.index}/4` : '-'}
              </span>
            </div>
          </div>
        }
      </div>
    </header>
  );
}

export default Header;
