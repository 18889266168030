import axios from 'axios';

class Integrations {
  /**
   * submitDataToWs
   *
   * Sends the specified data
   * to the webservice.
   *
   * @type function
   * @since 0.0.1
   *
   * @param object data
   * @return NA
   */
  submitDataToWs = async data => {
    const wsUrl = window.location.href
      .indexOf('localhost') !== -1
        ? '/ws/loans/flinks/verify'
        // : 'https://api-s.uat.questrade.com/loans/flinks/verify'; // staging
        : 'https://api.questrade.com/loans/flinks/verify'; // prod

    try {
      await axios({
        method: 'POST',
        url: wsUrl,
        data: JSON.stringify(data),
        headers: {
          // 'Authorization': '0f48195e-7377-11ea-bc55-0242ac130003',
          'Content-Type': 'application/json',
        },
      });

      return true;
    } catch (err) {
      return true;
    }
  }
}

export default Integrations;
