import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

import Field from './Field';
import SelectField from './SelectField';

const Form = forwardRef((props, ref) => {
  const {
    noStyle = false,
    uniqueId,
    onSubmit,
  } = props;

  /** @ref Form */
  const theForm = useRef();

  /** @state object */
  const [formFields, setFormFields] = useState({});

  /** @state object */
  const [invalidFields, setInvalidFields] = useState([]);

  /** @state object */
  const [children, setChildren] = useState([]);

  /** @state object */
  const [formData, updateFormData] = useState({});

  // prepare children
  useEffect(() => {
    const prepareChildren = childrenList => {
      return React.Children.toArray(childrenList).map(child => {
        if (typeof child === 'string') return child;

        // check if this child is a form field
        const isFormField = (
          typeof child.type === 'function' &&
          child.type.formField
        );

        // check if the field is invalid
        const invalidField = isFormField &&
          invalidFields.filter(f => f.name === child.props.name);
        const fieldIsInvalid = isFormField && invalidField.length > 0;

        // add extra props
        const childProps = isFormField ? {
          uniqueId,
          formData,
          invalid: fieldIsInvalid && invalidField[0],
          updateValue: updateFormData,
        } : {
          ...(child.props.children && {
            children: prepareChildren(child.props.children)
          }),
        }

        // save form field information
        if (isFormField) setFormFields(
          prevFormFields => ({
            ...prevFormFields,
            [child.props.name]: {
              type: child.props.type || false,
              required: child.props.required || false,
            }
          })
        );

        // return cloned child
        return React.cloneElement(child, childProps);
      });
    }

    setChildren(prepareChildren(props.children));
  }, [props.children, invalidFields, formData, uniqueId]);

  /**
   * validateFormData
   *
   * Validates the form data.
   *
   * @type function
   * @since 0.0.1
   *
   * @param NA
   * @return boolean
   */
  const validateFormData = () => {
    const invalidFields = [];

    for (let name in formFields) {
      const { type, required } =
        formFields[name];
      let value = formData[name].replace(/_/g, '');

      // required
      if (required && value.length === 0) {
        invalidFields.push({
          name,
          message: 'This field is required.',
        });
        continue;
      }

      if (required || value.length !== 0) {
        let invalid = false;
        let message;

        // date
        if (type === 'date') {
          let [d,m,y] = value.split('-');
          value = [y,m,d].join('-');

          invalid = value.length < 10 ||
            isNaN((new Date(value)).getTime()) ||
            (new Date(value)) > (new Date());

          if (invalid) message = 'Incorrect format.';
        }

        // loan number
        else if (type === 'loanNumber') {
          invalid = value.length < 5;
        }

        // name
        else if (type === 'name') {
          let regex = /^([A-Za-z]+)$/g
          invalid = !regex.test(value.trim());
        }

        // phone
        else if (type === 'phone') {
          invalid = value.length < 14;
        }

        if (invalid && !message) message =
          'Please, verify this information.';

        if (invalid)
          invalidFields.push({
            name,
            message,
          });
      }
    }

    setInvalidFields(invalidFields);
    return invalidFields.length === 0;
  }

  /**
   * submit
   *
   * Validates and returns the form data
   * to the parent component.
   *
   * @type function
   * @since 0.0.1
   *
   * @param object event
   * @return NA
   */
  const submit = event => {
    if (event) event.preventDefault();
    if (theForm.current.disabled) {
      console.log('disabled');
      return;
    }

    const formIsValid = validateFormData();
    if (formIsValid && onSubmit) {
      // disable the form
      theForm.current.disabled = true;

      // submit the form
      const submission = onSubmit(formData);
      if (typeof submission === 'object') {
        submission.then(
          ({ success, message, reset }) => {
            // enable the form
            theForm.current.disabled = false;

            // reset
            if (reset) updateFormData(prevFormData => {
              for (let key in prevFormData)
                prevFormData[key] = '';
              return prevFormData;
            });
          }
        );
      }
    }
  }

  // make the submit method accessible
  // by the parent component
  useImperativeHandle(ref, () => ({ submit, formData }));

  // render
  return (
    <form
      ref={theForm}
      className={`form ${noStyle ? '' : 'styled'}`.trim()}
      onSubmit={submit}>
      {children}
    </form>
  );
});

export default Form;
export { Field, SelectField };
