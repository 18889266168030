import React, { useContext, useEffect, useState } from 'react';
import './SuccessStep.scss';

import GlobalContext from 'context/Global';

import Integrations from 'providers/Integrations';

import { CircularProgress } from '@material-ui/core';

import RichText from 'components/RichText/RichText';
import 'styles/_rich-text.scss';

import SuccessIcon from 'assets/img/prod/icons/final-success.png';

const SuccessStep = props => {
  const {
    setCurrentStep,
    history,
  } = props;

  /** @var boolean */
  const [isLoading, setLoading] = useState(true);

  /** @var object */
  const { data } = useContext(GlobalContext);

  // run initial checks
  useEffect(() => {
    // bail early if accessed directly
    if (!data.firstName) return history.push('/');

    // update the current step
    setCurrentStep({
      name: 'Bank Statement Authorization Complete',
      index: 4,
    });

    // eslint-disable-next-line
  }, []);

  // send data to the webservice
  useEffect(() => {
    if (data.loginId) {
      // format the data
      const [d,m,y] = data.dateOfBirth.split('-');
      data.dateOfBirth = [y,m,d].join('-');

      // submit the data
      const apiCtrl = new Integrations();
      const request = apiCtrl.submitDataToWs(data);
      request.then(success => setLoading(false));
    }
  }, [data]);

  // render
  return !data.firstName ? null : (
    <div className="step step-success">
      {isLoading ? (
        <div className="progress">
          <CircularProgress />
        </div>
      ) : (
        <>
          <header className="step__header">
            <i className="icon">
              <img
                src={SuccessIcon}
                alt="Success"
                className="el-img"
              />
            </i>
            <h2 className="step-title">
              Thank you for completing the online bank verification
            </h2>
          </header>

          <div className="step__content">
            <RichText>
              <p>Your bank account statements have been requested. A Community Trust representative will review the information and will follow-up with your mortgage broker.</p>
            </RichText>
          </div>
        </>
      )}
    </div>
  );
}

export default SuccessStep;
