import React from 'react';

const RichText = ({ contents, className, children }) => {
  /** @var object */
  const classNames = ['rich-text'];
  if (className) classNames.push(className);

  // render
  if (contents) {
    return (
      <div
        className={classNames.join(' ')}
        dangerouslySetInnerHTML={{ __html: contents }}>
      </div>
    );
  } else {
    return (
      <div className={classNames.join(' ')}>
        {children}
      </div>
    );
  }
}

export default RichText;
