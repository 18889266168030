import React, { useState } from 'react';
import 'App.scss';

import {
  BrowserRouter as Router,
  Switch,
  Route,
} from 'react-router-dom';

import {
  ThemeProvider,
  createMuiTheme,
} from '@material-ui/core';

import GlobalContext from 'context/Global';

import Header from 'components/Header/Header';
import Footer from 'components/Footer/Footer';

import FormStep from 'steps/FormStep/FormStep';
import BankStep from 'steps/BankStep/BankStep';
import SuccessStep from 'steps/SuccessStep/SuccessStep';
import ErrorStep from 'steps/ErrorStep/ErrorStep';

/** @var object */
const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#f27a21',
    },
    secondary: {
      main: '#333',
    },
  },
});

const App = () => {
  /** @state object */
  const [data, setData] = useState({});

  /** @state object */
  const [currentStep, setCurrentStep] = useState({});

  /** @var object */
  const localSteps = [
    {
      path: '/',
      Component: FormStep,
    },
    {
      path: '/choose-your-bank',
      Component: BankStep,
    },
    {
      path: '/success',
      Component: SuccessStep,
    },
    {
      path: '/error',
      Component: ErrorStep,
    },
  ];

  /**
   * updateData
   *
   * Updates the user data
   * for the final integration.
   *
   * @type function
   * @since 0.0.1
   *
   * @param object data
   * @return NA
   */
  const updateData = data => {
    setData(prevData => ({
      ...prevData,
      ...data,
    }));
  }

  // render
  return (
    <ThemeProvider theme={theme}>
      <GlobalContext.Provider value={{ data }}>
        <div className="App">
          <Router>
            <Header currentStep={currentStep} />
            <div id="steps">
              <Switch>
                {localSteps.map(
                  ({ Component, ...step }, key) => (
                    <Route
                      key={key}
                      exact
                      path={step.path}
                      render={renderProps => (
                        <Component
                          {...renderProps}
                          setCurrentStep={setCurrentStep}
                          updateData={updateData}
                        />
                      )}
                    />
                  )
                )}
              </Switch>
            </div>
          </Router>
          <Footer />
        </div>
      </GlobalContext.Provider>
    </ThemeProvider>
  );
}

export default App;
