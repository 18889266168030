import React, { useContext, useEffect } from 'react';
import './ErrorStep.scss';

import GlobalContext from 'context/Global';

import RichText from 'components/RichText/RichText';
import 'styles/_rich-text.scss';

import ErrorIcon from 'assets/img/prod/icons/final-error.png';

const ErrorStep = props => {
  const {
    setCurrentStep,
    history,
  } = props;

  /** @var object */
  const { data } = useContext(GlobalContext);

  // run initial checks
  useEffect(() => {
    // bail early if accessed directly
    if (!data.firstName) return history.push('/');

    // update the current step
    setCurrentStep({
      name: 'Service unavailable',
      index: false,
    });

    // eslint-disable-next-line
  }, []);

  // render
  return !data.firstName ? null : (
    <div className="step step-error">
      <header className="step__header">
        <i className="icon">
          <img
            src={ErrorIcon}
            alt="Error"
            className="el-img"
          />
        </i>
        <h2 className="step-title">
          The service is currently unavailable
        </h2>
      </header>

      <div className="step__content">
        <RichText>
          <p>Unfortunately the service is currently unavailable at the moment. Please try again later.</p>
          <p>Please contact your mortgage broker for alternative options.</p>
        </RichText>

        <div className="call-to-action">
          <a
            href="https://communitytrust.com"
            title="Go back to the homepage"
            className="btn">
            <span>Go back</span>
          </a>
        </div>
      </div>
    </div>
  );
}

export default ErrorStep;
