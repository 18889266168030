import React, { useContext, useEffect, useState } from 'react';
import './BankStep.scss';
import axios from 'axios';

import { isMobile } from 'react-device-detect';

import GlobalContext from 'context/Global';

import { CircularProgress } from '@material-ui/core';

const BankStep = props => {
  const {
    setCurrentStep,
    updateData,
    history,
  } = props;

  /** @var object */
  const { data } = useContext(GlobalContext);

  /** @state boolean */
  const [isReady, setReady] = useState(false);

  /** @state boolean */
  const [isLoading, setLoading] = useState(true);

  // run initial checks
  useEffect(() => {
    // bail early if accessed directly
    if (!data.firstName) return history.push('/');

    // check the Flinks Connect module status
    const request = axios.get('flinks-check-status.php');

    // if the request is successful,
    // load the iframe
    request.then(resp => {
      if (resp.data === 1) {
        setReady(true);
      } else {
        // go to the error page
        updateData({ error: true });
        history.push('/error');
      }
    })

    // if there's an error,
    // go to the error page
    request.catch(() => {
      updateData({ error: true });
      history.push('/error');
    });

    // eslint-disable-next-line
  }, []);

  // update the current step
  useEffect(() => {
    /** @var function */
    const handleMessage = ({ data }) => {
      // stop loading
      if (data.step === 'COMPONENT_LOAD_INSTITUTION_SELECTOR') {
        setLoading(false);
      }

      let currentStep = {
        name: 'Connect with your bank',
        index: 2,
      }

      switch (data.step) {
        // institution selector
        case 'COMPONENT_LOAD_INSTITUTION_SELECTOR':
          currentStep.index = 2;
          break;

          // credentials
        case 'COMPONENT_LOAD_CREDENTIAL':
          currentStep.index = 3;
          currentStep.name = 'Bank account verification';
          break;

        default:
          currentStep = false;
          break;
      }

      // update the current step information
      if (currentStep) setCurrentStep(currentStep);
    }

    // add event listener
    window.addEventListener('message', handleMessage);

    // cleanup
    return () => window.removeEventListener('message', handleMessage);

    // eslint-disable-next-line
  }, []);

  // save data and move to
  // the next step
  useEffect(() => {
    /** @var function */
    const handleMessage = ({ data }) => {
      if (data.step === 'REDIRECT') {
        updateData({
          loginId: data.loginId,
        });

        history.push('success');
      }
    }

    // add event listener
    window.addEventListener('message', handleMessage);

    // cleanup
    return () => window.removeEventListener('message', handleMessage);

    // eslint-disable-next-line
  }, []);

  /** @var object */
  const flinksOptions = {
    theme: 'light',
    customerName: 'Community Trust',
    innerRedirect: true,
    backgroundColor: 'F4F4F4',
    foregroundColor2: '231f20',
    foregroundColor1: 'f27a21',
    desktopLayout: true,
    headerEnable: true,
    institutionFilterEnable: false,
    fixedHeightEnable: isMobile ? true : false,
    accountSelectorEnable: true,
    language: 'en',
    accountSelectorMultiple: true,
    accountSelectorNoTitle: true,
    daysOfTransactions: 'Days365',
    detailsAndStatementEnable: true,
    monthsOfStatements: 'Months12'
    // showAllOperationsAccounts: true,
  }

  /** @var object */
  let flinksParams = [];
  for (let key in flinksOptions) {
    flinksParams.push(`${key}=${flinksOptions[key]}`);
  }

  /** @var string */
  // let iframeUrl = 'https://toolbox-iframe.private.fin.ag'; // staging
  let iframeUrl = 'https://ctc-iframe.private.fin.ag'; // prod
  iframeUrl = `${iframeUrl}?${flinksParams.join('&')}`;

  // render
  return (
    <div className="step step-bank">
      <div className="step__content">
        {isLoading &&
          <div className="progress">
            <CircularProgress />
          </div>
        }
        <iframe
          title="Flinks Connect"
          className="flinks-connect"
          scrolling="no"
          src={isReady ? iframeUrl : null}>
        </iframe>
      </div>
    </div>
  );
}

export default BankStep;
